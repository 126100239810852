<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import { queryEnterpriseInfo} from '@/api/enterprise'

import { getMemberSettingInfo,getMemberUserIdentity,queryUserCalcTime,queryUserPackage,queryActivityFlag} from '@/api/user'
import Cookies from "js-cookie";

// import versionTood from '@/utils/versionUpdate';
export default {
  name: 'App',
  data(){
    return{
      userInfo:{},
      isRouterAlive:true,
    }
  },
  provide() { // 父组件中返回要传给下级的数据
    return {
      reload: this.reload
    }
  },
  methods:{
    //更改语言后刷新数据
    async reload() {
      // versionTood.isNewVersion('app')
      //提前判断防止控制台继续请求报错
      this.isRouterAlive = false
      let lang='zh'
      if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
          lang='en'
      }
      this.$i18n.locale =lang
      if((localStorage.getItem('loginStatus')&&localStorage.getItem('loginStatus')=='true')||Cookies.get("VM_APP_COOKIE")){
        let res2 = await getMemberSettingInfo()
        if(res2&&res2.entity){
          this.userInfo = JSON.parse(JSON.stringify(res2.entity));
        }
      }
      this.$nextTick(async()=>{
        this.isRouterAlive = true
        if((localStorage.getItem('loginStatus')&&localStorage.getItem('loginStatus')=='true')||Cookies.get("VM_APP_COOKIE")){
          localStorage.setItem('loginStatus','true')
          this.$store.commit('changeLoginStatus',true)
          let lang='zh'
          if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
              lang='en'
          }
          this.$i18n.locale =lang
          await this.getUserData()
        }else{
          localStorage.removeItem('loginStatus')
          this.$store.commit('changeLoginStatus',false)
        }
        if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
          document.title='Virtual Engineer'
        }else{
          document.title='数智工程师'
        }
      })
    },

    // 请求企业和个人数据，放到userInfo;
    async getUserData() {
      try {
          if(this.userInfo.userLanguage){
            // console.log(this.userInfo.userLanguage);
            let lang='zh'
            if(this.userInfo.userLanguage&&this.userInfo.userLanguage=='EN_US'){
                lang='en'
            }
            this.$i18n.locale =lang
            if(!localStorage.getItem('language')||localStorage.getItem('language')!==lang){
              localStorage.setItem('language',lang);
              this.reload()//全局重新加载
              return
            }
          }
          //存储认证状态
          if(this.userInfo.userStatus){
            switch(this.userInfo.userStatus){
                case "NORMAL":
                  localStorage.setItem("userStatus",'passed');
                  this.$store.commit('changeUserStatus','passed');
                  break;
                default:
                  localStorage.setItem("userStatus",'reject');
                  this.$store.commit('changeUserStatus','reject');
            }
          }

          //存储购买状态(屏蔽)
          localStorage.setItem("buyBenefit",this.userInfo.buyBenefit);
          this.$store.commit('changeBuyBenefit',this.userInfo.buyBenefit);
          
          //存储试用状态(屏蔽)
          localStorage.setItem("trialStage", this.userInfo.trialApplyStatus);
          this.$store.commit('changeTrialStage',this.userInfo.trialApplyStatus)
          localStorage.setItem("accountType", this.userInfo.accountType);
          
          //存储账户类型标签(屏蔽)
          let res3 = await getMemberUserIdentity()
          if(res3.success){
            if(!res3.entity.userVipType){
              this.userInfo.tagBtnText=''
            }else{
              switch(res3.entity.userVipType){
                case "COMMON_VIP":this.userInfo.tagBtnText=this.$t('Tag.CommonVIP');break;
                case "TRIAL_VIP":this.userInfo.tagBtnText=this.$t('Tag.TrialVIP');break;
                case "ENTERPRISE_VIP":this.userInfo.tagBtnText=this.$t('Tag.EnterpriseVIP');break;
              }
              if(res3.entity.expireTimeStamp&&(new Date().getTime()>=res3.entity.expireTimeStamp)){
                this.userInfo.tagBtnText=this.$t('SubMenu.expired');
              }
            }
          }
          
          switch(this.userInfo.accountType){
            case 'PERSON':
            this.userInfo.accountType = this.$t('SubMenu.person');
            break;
            case 'BUSINESS':this.userInfo.accountType = this.$t('SubMenu.businessMaster');break;
            case 'BUSINESS_SUB':this.userInfo.accountType = this.$t('SubMenu.businessSub');break;
          }
          if(this.userInfo.accountType=='BUSINESS'||this.userInfo.accountType=='BUSINESS_SUB'){
            let res = await queryEnterpriseInfo();
            this.userInfo={...res.entity,...this.userInfo}
            switch(res.entity.accountStatus){
              case 'CERTIFIED':this.userInfo.accountStatus=this.$t('SubMenu.certified');break;
              case 'NO_CERTIFIED':this.userInfo.accountStatus=this.$t('SubMenu.notCertified');break;
            }
          }
          //查询核时统计数据
          // let calcRes=await queryUserCalcTime()
          // if(calcRes.success){
          //   this.userInfo={...this.userInfo,...calcRes.entity}
          // }
          
          //查询是否在活动期
          this.userInfo.activityFlag=false
          let activityRes=await queryActivityFlag({activityCode:'PRODUCT_LAUNCH_ACTIVITY'})
          if(activityRes.success&&activityRes.entity){
            this.userInfo.activityFlag=activityRes.entity
          }
          //查询用户套餐
          let packageRes=await queryUserPackage()
          if(packageRes.success){
            this.userInfo={...this.userInfo,...packageRes.entity}
          }
        this.$store.commit('changeUserInfo',this.userInfo)
        this.$forceUpdate()
      } catch (err) {
        //统一处理异常
        // console.error (err)
      }finally{
      }
    },
  },
 async mounted(){
      // 监听storage事件,更新时来刷新其他标签页
      // window.addEventListener('storage', function(e) {
      //   if (e.key === 'refresh') {
      //     console.log('refresh');
      //     window.location.reload(true);
      //   }
      // });
  },
  async created(){
    window.addEventListener("setItemEvent",()=>{
      const computedUse = () => {
        let cache = 0
        if(localStorage&&localStorage.length){
          for(let key in localStorage) {
            if (localStorage.hasOwnProperty(key)&&key!='setItem') {
              cache += localStorage.getItem(key).length
            }
          }
        }
        return (cache / 1024).toFixed(2)
      }
      
      (async () => {
        const useCache = computedUse()
        if(useCache>5000){
          //移除用户信息
          let arr=Object.keys(localStorage)
          arr.forEach((item)=>{
            if(item.includes('status')
            ||item.includes('/design')
            ||item.includes('productUUID')
            ||item.includes('queryList')
            ||item.includes('contrast')
            ||item.includes('/dxf')
            ||item.includes('queryList')
            ||item.includes('tempArr')
            ||item.includes('/create')){
              localStorage.removeItem(item)
            }
          })
          this.$router.go(0)
        }
      })()
    })
  },
  destroyed(){
  },
  watch:{
    $route: {
        async handler(newVal,oldVal) {
          if(newVal&&newVal.path&&oldVal&&oldVal.path){
            if(newVal.path.split('/')[1]!=oldVal.path.split('/')[1]&&newVal.name!=='NotFound'){
              this.reload()
            }
          }
        },
        deep: true,
    },
  },
}
</script>
<style lang="scss">
html,
body {
  height: 100%;
  body{
    min-width: 1080px;
  }
  
}
* {
    /*IE*/
    /*三角箭头的颜色*/
    scrollbar-arrow-color:  transparent 0%, ;
    /*滚动条滑块按钮的颜色*/
    scrollbar-face-color: #b6b6b6;
    /*滚动条整体颜色*/
    scrollbar-highlight-color: #979797;
    /*滚动条阴影*/
    scrollbar-shadow-color: #666;
    /*滚动条轨道颜色*/
    scrollbar-track-color: transparent;
    /*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
    scrollbar-3dlight-color: #666;
    /*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
    scrollbar-darkshadow-color: #666;
    /*滚动条基准颜色*/
    scrollbar-base-color: #b6b6b6;
    /*火狐*/
    scrollbar-color: #b6b6b6 transparent; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
    word-break: normal;
  }

  /*谷歌*/
  *::-webkit-scrollbar{
    width: .375rem;
    height: .375rem;
    background: transparent; /* 滚动条的背景颜色 */
  }
  *::-webkit-scrollbar-track {
    background: transparent; /* 轨道的背景颜色 */
    border-radius: .375rem;
  }
  *::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to top, #b6b6b6 0%, #979797 100%);
    border-radius: .375rem;
  }
  *::-webkit-scrollbar-button {
      display: none; /* 隐藏箭头按钮 */
  }

  *::-webkit-scrollbar-corner {
      background: transparent; /* 角落的背景颜色 */
  }
#app {
  height: 100%;
  font-family:Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  input, textarea {
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
  }
}

</style>
