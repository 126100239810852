import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from "js-cookie";
import enLocale from '@/assets/lang/en.js'
import zhLocale from '@/assets/lang/zh.js'
function getLangObj(){
  let langObj=null
  if(localStorage.getItem('language')&&localStorage.getItem('language')=='en'){
    langObj=enLocale
  }else{
    langObj=zhLocale
  }
  return langObj
}
Vue.use(Vuex)

export default new Vuex.Store({
  // 配置项目严格模式
  // 开发环境（development）测试环境下设置为严格模式，生产环境（production）下取消
  // strict: process.env.NODE_ENV == "development",
  // 放全局的数据源
  // 因为每次刷新页面都会重置状态管理器
  state: {
    //todo enterprise
    memberInfo:null,
    enterpriseInfo:null,

    //todo engineer
    //产品列表
    proList: [
    ],

    //侧边栏是否搜索
    collapse:false,

    //产品列表筛选选项
    options:{},

    // 排序条件
    sortObj:{},

    //总条目
    totalNum:'',
    //列表展示状态
    num: 1,
    //分页跳转
    pageNo:1,
    //表格是否显示正在加载
    tableLoading:true,
    //拖拽添加到分组的信息
    proId: "",
    groupId: "",
    shallDrag: "", //展示可拖拽的目标元素


    // todo 结果数据
    productUUID:localStorage.getItem('productUUID'),
    resultType:'',//默认方案英文
    schemeValue:'',//默认方案中文

    //滚动定位
    scrollActive: {
      index:0,
      path:"$.report.partProps.0"
    },
    pdfObj:null,//结果pdf
    loading:true,//pdf加载

    //todo 设计数据
    designParams:{},//创建设计所需参数
    designObj:{},//创建设计返回对象

    laminationEditObj:null,// 冲片研发库对象

    //已有产品数据
    designProductUUID:'',
    designProResultObj:'',

    //新建设计时间戳（保存卸载时销毁）
    createDesignId:'',

    //暂存的应用领域的参数
    applySceneInfo:{},

    //todo 用户认证
    userInfo:null,//用户信息
    loginStatus:(localStorage.getItem('loginStatus')&&localStorage.getItem('loginStatus')=='true')&&(Cookies.get("VM_APP_COOKIE")||localStorage.getItem('token')),//用户是否登录
    userStatus:localStorage.getItem('userStatus'),//用户认证状态————// none:未认证,review:认证中,reject:认证已拒绝,passed:认证已通过
    buyBenefit:localStorage.getItem('buyBenefit'),//用户是否购买————已购买true;未购买false
    trialStage:localStorage.getItem('trialStage'),//用户试用状态————
    // NONE:未申请;ke
    // FIRST_REVIEW:初次申请审核中;
    // FIRST_PASSED:初次申请已通过;
    // FIRST_REJECTED:初次申请已拒绝;ke
    // FIRST_EXCEED:初次申请已到期;ke
    // SECOND_REVIEW:再次申请审核中;
    // SECOND_PASSED:再次申请已通过;
    // SECOND_REJECTED:再次申请已拒绝;ke
    // SECOND_EXCEED:再次申请已到期;

    //todo 用户设置
    //订单列表
    orderList:[

    ],
  },
  getters: {
    //类似state的计算属性
    productList:(state)=>{
      console.log(state.proList);
      let arr = JSON.parse(JSON.stringify(state.proList));
      arr.forEach((item) => {
        if (!item.applicationScene) {
          item.applicationScene = '--';
        }
        if (!item.motorCategory) {
          item.motorCategory = '--';
        }
        if (!item.productName) {
          item.productName = '--';
        }
        if (!item.motorStructure) {
          item.motorStructure = '--';
        }
        if (!item.motorType) {
          item.motorType = '--';
        }
          if (!item.computeStartTime) {
            item.computeStartTime = '--';
          }
          if (!item.computeFinishTime) {
            item.computeFinishTime = '--';
          }
          if (!item.createTime) {
            item.createTime = '--';
          }

          // NOT_COMPUTE:未计算;COMPUTING:计算中;FAIL:计算失败;SUCCESS:计算成功
          switch (
            item.productStatus
          ) {
            case 'SUCCESS':
              item.productStatus = getLangObj().routeMenu.Success;
              break;
            case 'FAIL':
              item.productStatus = getLangObj().routeMenu.Fail;
              break;
            case 'COMPUTING':
              console.log(getLangObj().routeMenu.Computing);
              item.productStatus = getLangObj().routeMenu.Computing;
              break;
            case 'NOT_COMPUTE':
              item.productStatus = getLangObj().routeMenu.Draft;
              break;
          }

        if (!item.ratedPower) {
          item.ratedPower = "--";
        }
        if (!item.ratedSpeed) {
          item.ratedSpeed = "--";
        }
        if (!item.ratedTorque) {
          item.ratedTorque = "--";
        }
        if (!item.ratedEfficiency) {
          item.ratedEfficiency = "--";
        }
        if (!item.insulationClass) {
          item.insulationClass = "--";
        }else{
          switch(item.insulationClass){
            case "Y":item.insulationClass="Y 90℃";break;
            case "A":item.insulationClass="A 105℃";break;
            case "E":item.insulationClass="E 120℃";break;
            case "B":item.insulationClass="B 130℃";break;
            case "F":item.insulationClass="F 155℃";break;
            case "H":item.insulationClass="H 180℃";break;
            case "C":item.insulationClass="C >180℃";break;
          }
        }
      });
      return arr;
    },
    //trialAvailable可显示试用按钮
    trialAvailable:(state)=>{
      if(
      // state.userInfo.accountType!=='企业主账户'&&
      state.userInfo&&state.userInfo.accountType&&state.trialStage&&!state.userInfo.activityFlag
      &&state.userInfo.accountType!==getLangObj().SubMenu.businessSub
      &&state.userInfo.buyBenefit==false&&(state.trialStage=='NONE'||state.trialStage=='FIRST_REJECTED'//首次试用申请拒绝
      // ||state.trialStage=='FIRST_EXCEED'
      // ||state.trialStage=='SECOND_REJECTED'
      // ||state.trialStage=='SECOND_EXCEED'
      )){
        return true
      }else{
        return false
        // return true//todo 测试
      }
    },
  },
  mutations: {
    //todo enterprise
    changeMemberInfo(state,value){
      state.memberInfo=value
    },
    changeEnterpriseInfo(state,value){
      state.memberInfo=value
    },

    //todo engineer
    //改变请求选项
    changeOptions(state, value){
      state.options = value;
    },
    //改变页码
    changePageNo(state, value){
      state.pageNo = value;
    },
    //改变排序
    changeSortObj(state, value){
      state.sortObj = value;
    },
        
    //项目列表
    changeProlist(state, args) {
      
      //冲片导出后单独更新
      if(!args||Array.isArray(args)){
        state.proList = args;
      }else{
        let index = state.proList.findIndex(ite=>ite.productUUID==args.productUUID)
        if (index!=-1) {
          state.proList[index]={...state.proList[index],...args}
          state.proList=JSON.parse(JSON.stringify(state.proList))
        }
      }
    },
    
    //项目总数
    changeTotalNum(state, value) {
      state.totalNum = value;
    },

    //呈现列表/卡片样式
    changeNum(state, value) {
      state.num = value;
    },

    //表格是否显示正在加载
    changeTableLoading(state, value) {
      state.tableLoading = value;
    },
    //侧边栏是否收缩
    changeCollapse(state, value) {
      state.collapse = value;
    },

    //拖拽
    changeDragProId(state, value) {
      state.proId = value;
    },
    changeDragGroupId(state, value) {
      state.groupId = value;
    },
    changeShallDrag(state, value) {
      state.shallDrag = value;
    },

    // todo 结果数据
    changeProductUUID(state, value) {
      localStorage.setItem('productUUID',value)
      state.productUUID = value;
    },
    //方案
    changeResultType(state, value) {
      state.resultType = value;
    },
    changeSchemeValue(state, value) {
      state.schemeValue = value;
    },
    changeScrollActive(state, value) {
      state.scrollActive = value;
    },
    //结果页pdf地址
    changePdfUrl(state, value) {
      state.pdfObj = value;
    },
    //加载
    changePdfLoading(state, value) {
      state.loading = value;
    },

    // todo 设计页面数据
    changeDesignParams(state,value){
      state.designParams=value
    },//创建设计所需参数
    
    changeDesignObj(state,value){
      state.designObj=value
    },//创建设计返回对象

    laminationEditObj(state,value){
      state.designObj=value
    },//创建设计返回对象

    createDesignId(state,value){
      state.createDesignId=value
    },//创建设计产品的id

    changeApplySceneInfo(state,value){
      state.applySceneInfo=JSON.parse(JSON.stringify(value))
    },//改变应用领域参数

    //todo 用户认证
    changeUserInfo(state,value){
      state.userInfo=value
    },//用户信息
    changeLoginStatus(state,value){
      state.loginStatus=value
    },//用户是否登录
    changeUserStatus(state,value){
      state.userStatus=value
    },//用户认证状态
    changeBuyBenefit(state,value){
      state.buyBenefit=value
    },//用户认证状态
    changeAccountType(state,value){
      state.accountType=value
    },//PERSON 普通用户；BUSINESS 企业主账户；BUSINESS_SUB 企业子账户；TRIAL 正在试用；PURCHASE 已购买用户；
    changeTrialStage(state,value){
      state.trialStage=value
    },
    //NONE:未申请;
   //FIRST_REVIEW:初次申请审核中;FIRST_PASSED:初次申请已通过;FIRST_REJECTED:初次申请已拒绝;FIRST_EXCEED:初次申请已到期;
    //SECOND_REVIEW:再次申请审核中;SECOND_PASSED:再次申请已通过;SECOND_REJECTED:再次申请已拒绝;SECOND_EXCEED:再次申请已到期;
  },
  actions: {
    //保存异步方法，也需要调用mutations修改数据
    // loginAction(context,value){
    //   setTimeout(()=>{
    //     context.commit('changeLoginState',value)
    //   },2000)
    // }
  },
  modules: {},
});
